// Copyright The OpenTelemetry Authors
// SPDX-License-Identifier: Apache-2.0

import { url } from 'inspector';
import { v4 } from 'uuid';

interface ISession {
  userId: string;
  currencyCode: string;
  geography: string;
  device: string;
}

const sessionKey = 'session';
const defaultSession = {
  userId: v4(),
  currencyCode: 'USD',
  geography: 'Not Set - Default',
  device: 'Not Set - Default'
};

const SessionGateway = () => ({
  getSession(): ISession {
    if (typeof window === 'undefined') {
      return defaultSession;
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("device") && urlParams.has("geography")) {
      defaultSession.device = urlParams.get("device") || "Not Set - URL Params";
      defaultSession.geography = urlParams.get("geography") || "Not Set - URL Params";
    }

    const session = sessionStorage.getItem(sessionKey);
    
    if (!session) {
      sessionStorage.setItem(sessionKey, JSON.stringify(defaultSession));
    } 

    return JSON.parse(session || JSON.stringify(defaultSession)) as ISession;
  },
  setSessionValue<K extends keyof ISession>(key: K, value: ISession[K]) {
    const session = this.getSession();

    sessionStorage.setItem(sessionKey, JSON.stringify({ ...session, [key]: value }));
  },
});

export default SessionGateway();
